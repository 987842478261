import React from 'react';
import {Container, Row} from 'react-bootstrap';

const Footer = () => {
	return (
		<>
			<footer>
				<Container>
					<Row>
						<div class='col-sm-8 links'>
							<ul>
								<li class='first leaf'>
									<a href='//www.motoi.com.au' title='About Motoi'>
										Home
									</a>
								</li>
								<li class='expanded'>
									<a
										href='//www.motoi.com.au/subscription-details'
										title='Subscription Details'
									>
										Special Offers
									</a>
									<ul>
										<li class='first last leaf'>
											<a
												href='//www.motoi.com.au/unsubscribe'
												title='Unsubscribe'
											>
												Unsubscribe
											</a>
										</li>
									</ul>
								</li>
								<li class='expanded'>
									<a href='//www.motoi.com.au/payment-options'>
										Renew a policy
									</a>
									<ul>
										<li class='first leaf'>
											<a href='//www.motoi.com.au/node/29'>Pay by the month</a>
										</li>
										<li class='leaf'>
											<a href='//www.motoi.com.au/pay-online'>Pay online</a>
										</li>
										<li class='leaf'>
											<a href='//www.motoi.com.au/pay-mail'>Pay by mail</a>
										</li>
										<li class='leaf'>
											<a
												href='//www.motoi.com.au/pay-person'
												title='Pay in person'
											>
												Pay in person
											</a>
										</li>
										<li class='last leaf'>
											<a href='//www.motoi.com.au/pay-phone'>Pay by phone</a>
										</li>
									</ul>
								</li>

								<li class='expanded'>
									<a href='//www.motoi.com.au/insurance-information'>
										Insurance Information
									</a>
									<ul>
										<li class='first leaf'>
											<a href='//www.motoi.com.au/fsg'>FSG</a>
										</li>
										<li class='leaf'>
											<a
												href='//www.motoi.com.au/code-practice'
												title='Code of Practice'
											>
												Code of Practice
											</a>
										</li>
										<li class='leaf'>
											<a
												href='//www.motoi.com.au/pds-policy-wording'
												title='PDS &amp; Policy Wording'
											>
												PDS &amp; Policy Wording
											</a>
										</li>
										<li class='leaf'>
											<a
												href='//www.motoi.com.au/target-market-determination'
												title='Target marget determinations'
											>
												Target market determinations
											</a>
										</li>
										<li class='leaf'>
											<a href='//www.motoi.com.au/terms-conditions'>
												Terms &amp; Conditions
											</a>
										</li>
										<li class='leaf'>
											<a href='//www.motoi.com.au/duty-disclosure'>
												Duty of Disclosure
											</a>
										</li>
										<li class='last leaf'>
											<a href='//www.motoi.com.au/personal-information'>
												Personal Information
											</a>
										</li>
									</ul>
								</li>

								<li class='last expanded'>
									<a
										href='//www.motoi.com.au/price-guarantee'
										title='Price Guarantee'
									>
										Price Guarantee
									</a>
									<ul>
										<li class='first last leaf'>
											<a href='//www.motoi.com.au/roadside-assist'>
												Roadside Assist
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</div>
						<div class='col-xs-12 col-sm-4'>
							<h3>For enquiries:</h3>
							<div class='phone'>
								Phone: <a href='tel:0411 450 561'>0411 450 561</a> or{' '}
								<a href='tel:1300 762 885'>1300 762 885</a>
							</div>
							<div class='email'>
								Email:{' '}
								<a
									href='mailto:enquiries@motorcycle-insurance.com.au'
									class='email'
								>
									enquiries@motorcycle-insurance.com.au
								</a>
							</div>
						</div>
					</Row>
					<Row>
						<div class='col-sm-12 copyright'>
							Motorcycle Management Services Pty Ltd trading as
							motorcycle-insurance.com.au is an authorised representative of QBE
							Insurance (Australia) Ltd.
							<br />
							&copy; Copyright 2022 Motorcycle Management Services Pty Ltd. All
							rights reserved. ABN 42098931330 | ACN 098931330.
						</div>
					</Row>
				</Container>
			</footer>
		</>
	);
};

export default Footer;
